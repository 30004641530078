import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';

export const BulletArrow = ({ size = 20, color = 'black', settings = false }) => {
  const { t } = useTranslation();
  const pathWidth = 20;
  const pathHeight = 12;
  const viewBoxWidth = 39;
  const viewBoxHeight = 39;
  const ratio = () => size / pathWidth;

  return (
    <svg
      aria-label={t('app.image.role-icon')}
      className="thumbnail-icon tiny"
      width={`${size}px`}
      height={`${size}px`}
      color={color}
    >
      <path
        className="cls-b6"
        style={{
          transform: `scale(${ratio()}, ${ratio()}) translate(${settings ? (viewBoxWidth - pathWidth) / 2 : 0}px, ${
            settings ? (viewBoxHeight - pathHeight) / 2 : (pathWidth - pathHeight) / 2
          }px)`,
        }}
        fill={color}
        d="M13.94 -2.64891e-07L12.93 0.999882L17.2657 5.29358L-4.01689e-07 5.29358L-4.63508e-07 6.70784L17.2657 6.70784L12.93 11.0001L13.94 12L20 6.00071L13.94 -2.64891e-07Z"
      />
    </svg>
  );
};
