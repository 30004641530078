import React from 'react';
import { Text } from 'wix-ui-tpa';
import { TiersProgramSettings } from '@wix/ambassador-loyalty-v1-tier/types';
import { useSettings, useStyles } from '@wix/tpa-settings/react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { SimpleReward, SimpleRule, SimpleTier } from '../../../types/domain';
import settingsParams from '../settingsParams';
import stylesParams, { Alignment, TiersLayout } from '../stylesParams';
import { TierCards } from './tier-cards';
import { classes, style } from './tiers-program.st.css';
import { DataHook } from '../../../types/data-hook';
import { alignmentToStateMap } from '../../../utils/alignment-to-state-map';

interface TiersProgramProps {
  simpleTiers: SimpleTier[];
  simpleRules: SimpleRule[];
  simpleRewards: SimpleReward[];
  tiersProgramSettings: TiersProgramSettings;
}

export const TiersProgram: React.FC<TiersProgramProps> = ({
  simpleTiers,
  simpleRules,
  simpleRewards,
  tiersProgramSettings,
}) => {
  const styles = useStyles();
  const settings = useSettings();
  const { isMobile } = useEnvironment();
  const settingsTiersLayout = styles.get(stylesParams.tiersLayout);
  const settingsCardsPerRow = styles.get(stylesParams.tiersCardsPerRow);
  const showSubtitle = styles.get(stylesParams.showTiersPageSubtitle);
  const layout = isMobile ? TiersLayout.Cards : settingsTiersLayout;
  const cardsCount = simpleTiers.length + 1;
  const cardsPerRow = isMobile ? 1 : settingsCardsPerRow;

  return (
    <div
      className={style(classes.root, {
        alignment: alignmentToStateMap[styles.get(stylesParams.tiersHeaderAlignment) as Alignment],
      })}
      data-hook={DataHook.TiersProgram}
    >
      <div className={classes.headerSection}>
        {styles.get(stylesParams.showTiersPageTitle) ? (
          <Text tagName="h2" className={style(classes.sectionTitle, { hideSubtitle: !showSubtitle })}>
            {settings.get(settingsParams.tiersTitle)}
          </Text>
        ) : null}
        {showSubtitle ? (
          <Text className={classes.sectionSubtitle}>{settings.get(settingsParams.tiersSubtitle)}</Text>
        ) : null}
      </div>
      <TierCards
        {...{
          simpleTiers,
          simpleRules,
          simpleRewards,
          cardsPerRow,
          cardsCount,
          layout,
          tiersProgramSettings,
        }}
      />
    </div>
  );
};
