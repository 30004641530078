import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';

export const BulletCheckFilled = ({ size = 20, color = 'black', settings = false }) => {
  const { t } = useTranslation();
  const pathWidth = 21;
  const pathHeight = 21;
  const viewBoxWidth = 39;
  const viewBoxHeight = 39;
  const ratio = () => size / pathWidth;

  return (
    <svg aria-label={t('app.image.role-icon')} className="thumbnail-icon tiny" width={`${size}px`} height={`${size}px`}>
      <path
        className="cls-b6"
        style={{
          transform: `scale(${ratio()}, ${ratio()}) translate(${settings ? (viewBoxWidth - pathWidth) / 2 : 0}px, ${
            settings ? (viewBoxHeight - pathHeight) / 2 : 0
          }px)`,
        }}
        fill={color}
        d="M8.90531 14.3863L5.25 10.731L6.17794 9.80306L8.90531 12.5304L14.8221 6.61369L15.75 7.54163L8.90531 14.3863ZM10.5 0C4.70137 0 0 4.70137 0 10.5C0 16.2986 4.70137 21 10.5 21C16.2999 21 21 16.2986 21 10.5C21 4.70137 16.2999 0 10.5 0Z"
      />
    </svg>
  );
};
