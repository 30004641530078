import { useWixSdk } from '@wix/yoshi-flow-editor';
import { IWixSiteColor } from '@wix/tpa-settings';

export const useGetSiteColors = () => {
  const { Wix } = useWixSdk();

  const getSiteColors = async (): Promise<IWixSiteColor[]> =>
    new Promise((resolve) => Wix?.Styles.getSiteColors(resolve));

  return { getSiteColors };
};
